import React from 'react'
import './assets/style/main.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Stories from './components/Stories'
import Chapters from './components/Chapters'
import Chapter from './components/Chapter'
import NotFound from './components/NotFound'
import styles from './App.module.scss'
import { Header } from './components/Header'

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className={styles.app}>
        <Header />
        <div className={styles.view}>
          <Routes>
            <Route path="/" element={<Stories />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/stories/:book" element={<Chapters />} />
            <Route path="/stories/:book/:number" element={<Chapter />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}
