import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { textBlocks } from '../helpers/text'
import { apiGetChapter, apiSendReview } from '../service/api'
import { ReviewForm } from '../types'
import Block from './Block'
import styles from './Chapter.module.scss'
import { Chapter as ChapterType } from '../types'

function Chapter() {
  const sendReview = (form: ReviewForm) => {
    const dataToSend = {
      ...form,
      story_slug: book,
      chapter_number: number,
    }
    apiSendReview(dataToSend)
  }

  useEffect(() => {
    loadChapter()
  }, [])

  const loadChapter = async () => {
    await apiGetChapter(book, number)
      .then((res) => {
        const chapter: ChapterType = res.data
        setChapter(chapter)
        document.title = chapter.title
      })
      .catch(() => {
        navigate('/404')
      })
  }

  const formatTime = (time: string) => {
    return new Date(time).toLocaleString()
  }

  const { book = 'none', number = '00' } = useParams()
  const navigate = useNavigate()
  const [chapter, setChapter] = useState<ChapterType | null>(null)

  if (!chapter) return <span />

  const blocks = textBlocks(chapter.body)

  return (
    <div className={styles.chapter}>
      <div className={styles.header}>
        <Link className={styles.returnLink} to={`/stories/${book}`}>
          <span>← к книге</span>
        </Link>
        <h1 className={styles.title}>{chapter.title}</h1>
        <p>Обновлено: {formatTime(chapter.updated_at)}</p>
      </div>
      <hr />
      <div className={styles.text}>
        {blocks.map((block, index) => (
          <Block key={index} text={block} line={index} sendReview={sendReview} />
        ))}
      </div>
    </div>
  )
}

export default Chapter
