import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { apiGetStory } from '../service/api'
import { Story } from '../types'
import styles from './Chapters.module.scss'
import { ymGoal } from '../service/ym'

function Chapters() {
  useEffect(() => {
    loadStory()
  }, [])

  const loadStory = async () => {
    await apiGetStory(book)
      .then((res) => {
        const story: Story = res.data
        setStory(story)
        document.title = story.title
      })
      .catch(() => {
        navigate('/404')
      })
  }

  const formatTime = (time: string) => {
    return new Date(time).toLocaleString()
  }

  const { book = 'none' } = useParams()
  const navigate = useNavigate()
  const [story, setStory] = useState<Story | null>(null)

  if (!story) return null

  return (
    <div className={styles.chapters}>
      <Link to={`/`} className={styles.returnLink}>
        <span>← к списку книг</span>
      </Link>
      <hr />
      {story && (
        <div className={styles.storyInfo}>
          <div>
            <img src={`/covers/${story.cover}`} alt="book-cover" className={styles.cover} />
          </div>
          <div>
            <h1 className={styles.title}>{story.title}</h1>
            <p className={styles.genre}>{story.genres}</p>
            <p className={styles.year}>Время действия: {story.year} год</p>
            <p className={styles.summary}>{story.summary}</p>
          </div>
        </div>
      )}
      {!!story.chapters.length && (
        <ul className={styles.linksList}>
          {story.chapters.map((chapter, index) => (
            <li key={index} className={styles.listItem}>
              <Link
                key={chapter.id}
                to={`/stories/${book}/${chapter.number}`}
                className={styles.link}
                onClick={() => {
                  ymGoal(96230416, 'openChapter')
                }}
              >
                <span className="title">{chapter.title}</span>
              </Link>
              <p className="title">Обновлено: {formatTime(chapter.updated_at)}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Chapters
