import axios, { AxiosResponse } from 'axios'
import { ReviewRequest, SignInRequest, Tokens } from '../types'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || ''
const apiPath = `${apiEndpoint}/api`

const endpoints = {
  signIn: `${apiPath}/sign-in`,
  auth: `${apiPath}/auth`,
  postReview: `${apiPath}/reviews`,
  getStories: `${apiPath}/stories`,
  getStory: (story: string) => `${apiPath}/stories/${story}`,
  getChapter: (story: string, number: string) => `${apiPath}/stories/${story}/chapters/${number}`,
}

export const signIn = async (body: SignInRequest): Promise<AxiosResponse> => {
  return await axios.post(endpoints.signIn, body)
}

export const auth = async (tokens: Tokens): Promise<AxiosResponse> => {
  return await axios.post(endpoints.auth, tokens)
}

export const apiSendReview = (review: ReviewRequest) => {
  axios.post(endpoints.postReview, review)
}

export const apiGetStories = async (): Promise<AxiosResponse> => {
  return await axios.get(endpoints.getStories)
}

export const apiGetStory = async (story: string): Promise<AxiosResponse> => {
  return await axios.get(endpoints.getStory(story))
}

export const apiGetChapter = async (story: string, number: string): Promise<AxiosResponse> => {
  return await axios.get(endpoints.getChapter(story, number))
}
