import React, { useState } from 'react'
import styles from './Block.module.scss'
import { MdThumbUp, MdThumbDown, MdOutlineClear } from 'react-icons/md'
import cn from 'classnames'
import { ReviewForm } from '../types'

type BlockProps = {
  text: string
  line: number
  sendReview(form: ReviewForm): void
}

enum ReactionType {
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
}

const Block = (props: BlockProps) => {
  const onClickHandler = () => {
    if (!text.trim()) return
    setSelected(!selected)
  }

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReviewText(event.target.value)
  }

  const onSubmitReview = (event: React.FormEvent) => {
    event.preventDefault()
    const reviewForm = {
      line: realLine,
      text: text.trim(),
      review_text: reviewText.trim(),
      reaction,
    }
    props.sendReview(reviewForm)
    dropForm()
  }

  const dropForm = () => {
    setSelected(false)
    setReaction(ReactionType.Neutral)
    setReviewText('')
  }

  const { text, line } = props
  const realLine = line + 1

  const [selected, setSelected] = useState(false)
  const [reaction, setReaction] = useState<ReactionType>(ReactionType.Neutral)
  const [reviewText, setReviewText] = useState<string>('')

  const blockClassName = cn(styles.block, { [styles.blockSelected]: selected })
  const formClassName = cn(styles.commentForm, {
    [styles.commentFormGreen]: reaction == ReactionType.Positive,
    [styles.commentFormNeutral]: reaction == ReactionType.Neutral,
    [styles.commentFormRed]: reaction == ReactionType.Negative,
  })

  const iconSize = '3em'
  const greenColor = 'limegreen'
  const redColor = 'firebrick'

  const buttonTexts = {
    positive: 'Оставить положительный отзыв',
    neutral: 'Оставить отзыв',
    negative: 'Оставить негативный отзыв',
  }

  return (
    <>
      <div className={blockClassName}>
        <div className={styles.text} onClick={onClickHandler}>
          <span className={styles.lineNumber}>{realLine}</span>
          {text}
        </div>
        {selected && (
          <div className={styles.review}>
            <MdThumbUp
              className={styles.icon}
              size={iconSize}
              color={greenColor}
              onClick={() => {
                setReaction(ReactionType.Positive)
              }}
            />
            <MdOutlineClear
              className={styles.icon}
              size={iconSize}
              onClick={() => {
                setReaction(ReactionType.Neutral)
              }}
            />
            <MdThumbDown
              className={styles.icon}
              size={iconSize}
              color={redColor}
              onClick={() => {
                setReaction(ReactionType.Negative)
              }}
            />
            <form className={formClassName} onSubmit={(e) => onSubmitReview(e)}>
              <textarea className={styles.commentTextarea} value={reviewText} onChange={(e) => onChangeText(e)} />
              <button type="submit" className={styles.buttonBlue}>
                {reaction == ReactionType.Positive && buttonTexts.positive}
                {reaction == ReactionType.Neutral && buttonTexts.neutral}
                {reaction == ReactionType.Negative && buttonTexts.negative}
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  )
}

export default Block
