import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { AppState, Auth, AuthStatus } from '../store/types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { setUserAuthFailed, setUserAuthSuccess } from '../store/actions'
import { Dispatch } from 'redux'
import { signIn, auth } from '../service/api'
import { Link } from 'react-router-dom'

export const Header: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()

  const authHandler = async () => {
    const accessToken = Cookies.get('access') || ''
    const refreshToken = Cookies.get('refresh') || ''
    if (!accessToken || !refreshToken) {
      dispatch(setUserAuthFailed())
    }
    return await auth({ accessToken, refreshToken })
      .then((res) => {
        if (res.status == 200) {
          const { login, name, role } = res.data.user
          dispatch(setUserAuthSuccess({ login, name, role }))
        }
      })
      .catch((err) => {
        dispatch(setUserAuthFailed())
      })
  }

  const signInHandler = async () => {
    return await signIn({ login, password }).then((res) => {
      if (res.status == 200) {
        const { accessToken, refreshToken } = res.data.user.token
        Cookies.set('access', accessToken, { expires: 30 * 60 * 1000 }) // 30m
        Cookies.set('refresh', refreshToken, { expires: 24 * 60 * 60 * 1000 }) // 24h
        const { login, name, role } = res.data.user
        dispatch(setUserAuthSuccess({ login, name, role }))
      }
    })
  }

  useEffect(() => {
    // authHandler()
  }, [])

  // UI

  const authState: Auth = useSelector((state: AppState) => state.auth, shallowEqual)
  const [login, setLogin] = useState<string>('raido')
  const [password, setPassword] = useState<string>('secret123')
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false)

  const submitSignInForm = (event: React.FormEvent) => {
    event.preventDefault()
    signInHandler()
  }

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible)
  }

  return (
    <div className={styles.header}>
      <div className={styles.flex}>
        <Link to={`/`}>
          <img src="/booker_logo.png" alt="" />
          {/* <h1>Букер!</h1> */}
        </Link>
      </div>
      {/* <div className={styles.description}>
        <div>Книги и рассказы</div>
      </div> */}
      {/* {authState.status == AuthStatus.Success && (
        <p>
          Вы авторизованы {authState.user?.name}
          {authState.user?.role == 2 && <span> [Админ]</span>}
        </p>
      )}
      {authState.status == AuthStatus.Error && (
        <p>
          Вы авторизованы как гость.{' '}
          <span className={styles.spanButton} onClick={toggleForm}>
            Авторизоваться?
          </span>
        </p>
      )} */}
      {authState.status == AuthStatus.Error && isFormVisible && (
        <form onSubmit={submitSignInForm} className={styles.form}>
          <div className={styles.inputBlock}>
            <label htmlFor="login">Логин</label>
            <input id="login" type="text" value={login} onChange={(event) => setLogin(event.target.value)} />
          </div>
          <div className={styles.inputBlock}>
            <label htmlFor="password">Пароль</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <button type="submit">Войти</button>
        </form>
      )}
    </div>
  )
}
