export interface Article {
  id: number
  title: string
  body: string
}

export interface User {
  login: string
  name: string
  role: number
}

export enum AuthStatus {
  Await,
  Success,
  Error,
}

export type Auth = {
  status: AuthStatus
  user: User | null
}

export type AppState = {
  articles: Article[]
  auth: Auth
}

export type Action = {
  type: ActionTypes
  payload?: any
}

export type DispatchType = (args: Action) => Action

export enum ActionTypes {
  AddArticle,
  RemoveArticle,
  SignInSuccess,
  SignInFailed,
}
