import { ActionTypes, Action, AppState, Article, AuthStatus } from './types'

const initialState: AppState = {
  auth: {
    user: null,
    status: AuthStatus.Error,
  },
  articles: [
    {
      id: 1,
      title: 'post 1',
      body: 'Quisque cursus, metus vitae pharetra Nam libero tempore, cum soluta nobis est eligendi',
    },
    {
      id: 2,
      title: 'post 2',
      body: 'Harum quidem rerum facilis est et expedita distinctio quas molestias excepturi sint',
    },
  ],
}

export const reducer = (state: AppState = initialState, action: Action): AppState => {
  switch (action.type) {
    case ActionTypes.AddArticle: {
      const newArticle: Article = {
        id: Math.random(), // not really unique
        title: action.payload?.title || 'nope',
        body: action.payload?.body || 'nope',
      }
      return {
        ...state,
        articles: state.articles.concat(newArticle),
      }
    }
    case ActionTypes.RemoveArticle: {
      const updatedArticles: Article[] = state.articles.filter((article) => article.id !== action.payload?.id)
      return {
        ...state,
        articles: updatedArticles,
      }
    }
    case ActionTypes.SignInFailed: {
      return {
        ...state,
        auth: {
          status: AuthStatus.Error,
          user: null,
        },
      }
    }
    case ActionTypes.SignInSuccess: {
      return {
        ...state,
        auth: {
          status: AuthStatus.Success,
          user: action.payload,
        },
      }
    }
    default:
      return state
  }
}
