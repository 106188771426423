import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import { reducer } from './reducers'
import { Action, AppState, DispatchType } from './types'

const devToolsExtension = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const enhancers: [] = []
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension() as never)
}

const composedEnhancer = compose(applyMiddleware(thunk), ...enhancers)

export const store: Store<AppState, Action> & {
  dispatch: DispatchType
} = createStore(reducer, undefined, composedEnhancer)
