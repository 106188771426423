import { ActionTypes, Action, DispatchType, Article, User } from './types'

export function addArticle(article: Article) {
  const action: Action = {
    type: ActionTypes.AddArticle,
    payload: article,
  }

  return (dispatch: DispatchType) => dispatch(action)
  // return simulateHttpRequest(action)
}

export function removeArticle(article: Article) {
  const action: Action = {
    type: ActionTypes.RemoveArticle,
    payload: article,
  }
  return simulateHttpRequest(action)
}

export function setUserAuthFailed() {
  const action: Action = {
    type: ActionTypes.SignInFailed,
  }
  return (dispatch: DispatchType) => dispatch(action)
}

export function setUserAuthSuccess(user: User) {
  const action: Action = {
    type: ActionTypes.SignInSuccess,
    payload: user,
  }
  return (dispatch: DispatchType) => dispatch(action)
}

export function simulateHttpRequest(action: Action) {
  return (dispatch: DispatchType) => {
    setTimeout(() => {
      dispatch(action)
    }, 500)
  }
}
